<template>
    <div align="center" v-if="is_mounted">
        <div class="text-h4">Verifica dati del Simplo</div>
        <br>

        <hr>
        <br><br>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div class="row justify-center">
            <div class="col-12 col-md-5 q-pr-md" align="left">
                <h5 class="q-mt-sm q-mb-sm">Dati generali pratica</h5>

                <q-list  separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Cliente:</q-item-label>
                            <q-item-label caption>{{dati_pratica.nominativo.toUpperCase()}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Stato della pratica:</q-item-label>
                            <q-item-label caption>{{ dati_pratica.stato_pratica }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Numero interno:</q-item-label>
                            <q-item-label caption>{{dati_pratica.numero_pratica}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Numero pratica:</q-item-label>
                            <q-item-label caption>{{dati_pratica.numero}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Unità operativa richiedente:</q-item-label>
                            <q-item-label caption>{{dati_pratica.unita_operativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Persona operativa:</q-item-label>
                            <q-item-label caption>{{dati_pratica.operatore}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
                <br>
                <h5 class="q-mt-sm q-mb-sm">Dati della quietanza selezionata</h5>
                <q-list  separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Decorrenza:</q-item-label>
                            <q-item-label caption>{{dati_quietanze.decorrenza}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Scadenza:</q-item-label>
                            <q-item-label caption>{{ dati_quietanze.scadenza }}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Data di incasso:</q-item-label>
                            <q-item-label caption>{{ dati_quietanze.data_incasso }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Prodotto:</q-item-label>
                            <q-item-label caption>{{dati_quietanze.prodotto_id}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Fornitore:</q-item-label>
                            <q-item-label caption>{{dati_quietanze.fornitore}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Premio quietanza:</q-item-label>
                            <q-item-label caption>{{dati_quietanze.totale}} Euro</q-item-label>
                        </q-item-section>
                    </q-item>

                </q-list>
            </div>

            <div class="col-12 col-md-6 q-pr-md" align="left">

                <h5 class="q-mt-sm q-mb-sm">Elenco dei Simpli associati alla Quietanza</h5>

                <q-list v-for="(simplo,index) in lista_simpli.elenco" v-bind:key="index"
                    clickable
                    v-ripple
                    separator
                    bordered
                    @click.native="onScaricaDocumentoDaUrl(simplo.nome_file, simplo.url)">
                    <q-item style="cursor: pointer;">
                        <q-item-section>
                            <q-item-label>Compagnia:</q-item-label>
                            <q-item-label caption>{{simplo.compagnia.toUpperCase()}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Prodotto:</q-item-label>
                            <q-item-label caption>{{ simplo.prodotto }}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Nome del File:</q-item-label>
                            <q-item-label caption>{{ simplo.nome_file }}</q-item-label>
                        </q-item-section>
                        <q-item-section avatar>
                            <q-avatar color="orange-3" text-color="black" icon="mdi-download" />
                        </q-item-section>
                    </q-item>
                </q-list>

            </div>

            <div class="col-12 com-md-12"><hr></div>

            <div class="col-12 col-md-4 q-pr-md" align="center">
                <div class="text-h5">Rifiuta il simplo sul prodotto {{ dati_quietanze.prodotto_id }}</div>
                <br>
                <q-input
                    label="Descrivi il motivo del rifiuto"
                    v-model="note_rifiuto"
                    filled
                    type="textarea"
                />
                <br>
                <QQButton label="Rifiuta" color="blue-grey" icon="mdi-alert-circle-outline" size="md"
                    @click.native="onRifiutaSimpli"
                    :disable="pulsanti_disattivati"
                />
            </div>

            <div class="col-12 col-md-3">
                <br><br><br>
                <h5>OPPURE</h5>
            </div>

            <div class="col-12 col-md-4 q-pr-md" align="center">
                <div class="text-h5">Convalida il simplo sul prodotto {{ dati_quietanze.prodotto_id }}</div>
                <br>
                <br>
                <br>
                <br>
                <QQButton label="Convalida" color="blue-grey" icon="mdi-tooltip-check-outline" size="md"
                    @click.native="onConvalidaSimpli"
                    :disable="pulsanti_disattivati"
                />
            </div>

            <div class="col-12 col-md-12 q-pr-md" align="center">
                <hr>
                <QQButton label="Indietro" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
            </div>

        </div>
    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    //import QQElencoDocumentiPratica from "@/components/QQElencoDocumentiPratica"
    import policy from "@/libs/policy";
    import { mapFields } from "vuex-map-fields";
    import { mapState, mapActions } from "vuex";
    import commonLib from "@/libs/commonLib";
    import axios from 'axios';

    export default {
        name: "VisualizzaDatiSimplo",
        data() {
            return {
                dati_pratica: {},
                is_mounted: false,
                is_loading: true,
                id_pratica: "",
                note_rifiuto: "",
                dati_quietanze: {},
                lista_simpli: [],
                pulsanti_disattivati: false
            }
        },
        components: {
            //QQElencoDocumentiPratica,
            QQButton
        },
        computed: {
            ...mapFields("gestioneSimpli", {
                idpratica: "idpratica",
                idcontratto: "idcontratto",
                idquietanza: "idquietanza",
                guid_pratica: "guid_pratica",
                // is_loading: "is_loading"
            }),
            isSede() {
                return policy.isSede();
            }
        },
        methods: {
            ...mapActions({
                fetchDatiPratica: "gestionePratiche/fetchDatiPratica",
                rifiuta_simplo_su_pratica: "gestioneSimpli/rifiuta_simplo_su_pratica",
                fetchElencoContrattiQuietanze: "gestionePratiche/fetchElencoContrattiQuietanze",
                fetchSimpliQuietanza: "gestioneSimpli/fetchSimpliQuietanza",
                valida_simplo_su_pratica: "gestioneSimpli/valida_simplo_su_pratica"
            }),
            ...mapState({
                formPratica: state => state.gestionePratiche.formPratica,
                formPreventivo: state => state.formPreventivo
            }),
            async onScaricaDocumentoDaUrl(filename,url) {
                var filename_minuscolo = filename.toLowerCase();
                filename = filename_minuscolo.replace(".pdf","");

                if ((commonLib.isEmpty(url)) || (url.toLowerCase() === "documento non trovato")) {

                    this.$q.dialog({
                        title: 'Attenzione',
                        message:  "documento non trovato",
                    });

                    return;
                }

                await axios({
                    url:url,
                    method:'GET',
                    responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                   const url = window.URL
                       .createObjectURL(new Blob([response.data]));
                   const link = document.createElement('a');
                   link.href = url;
                   link.target = "_blank";
                   link.setAttribute('download', filename+".pdf");
                   document.body.appendChild(link);
                   link.click();
                   document.body.removeChild(link);
                });
            },
            onRifiutaSimpli() {
                // Controlla che sia stato inserito il motivo del rifiuto
                if (this.note_rifiuto === "") {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Devi descrivere il motivo del rifiuto'
                    });

                    return;
                }

                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "No",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, procedi"
                    },
                    title: 'Attenzione',
                    message: 'Procedo con il rifiuto dei Simpli?',
                }).onOk(async () => {
                    this.is_loading = false;
                    this.pulsanti_disattivati = true;

                    await this.rifiuta_simplo_su_pratica({
                        guid_pratica: this.guid_pratica,
                        id_quietanza: this.idquietanza,
                        note: this.note_rifiuto
                    });

                    this.is_loading = true;

                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Rifiuto dei Simpli effettuato.'
                    });

                    this.pulsanti_disattivati = false;
                    this.$router.push({name : "Simpli.SimpliDaValidare"});
                });
            },
            onConvalidaSimpli() {
                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "No",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, procedi"
                    },
                    title: 'Attenzione',
                    message: 'Procedo con la validazione dei Simpli?',
                }).onOk(async () => {
                    this.is_loading = false;
                    this.pulsanti_disattivati = true;

                    await this.valida_simplo_su_pratica({
                        guid_pratica: this.guid_pratica,
                        id_quietanza: this.idquietanza
                    });

                    this.is_loading = true;

                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Validazione dei Simpli effettuata.'
                    });

                    this.pulsanti_disattivati = false;
                    this.$router.push({name : "Simpli.SimpliDaValidare"});
                });

            },
            onIndietroClicked() {
                this.$router.push({name : "Simpli.SimpliDaValidare"});
            }
        },
        async mounted() {
            this.is_mounted = false;

            this.dati_pratica = await this.fetchDatiPratica({
                numero_pratica: this.guid_pratica,
                token: ""
            });

            this.id_pratica = this.idpratica+"";

            // this.dati_quietanze
            var dati_contratto_quietanze = await this.fetchElencoContrattiQuietanze(this.guid_pratica);

            // Scansiona le quietanze per trovare quella voluta
            var contratto = dati_contratto_quietanze.find(c => {
                return c.idcontratto === this.idcontratto;
            });

            console.log("contratto:",contratto,this.idcontratto);

            this.dati_quietanze = contratto.quietanze.find(q => {
                return q.idquietanza === this.idquietanza;
            });

            console.log("dati quietanze:",this.dati_quietanze);

            this.lista_simpli = await this.fetchSimpliQuietanza({
                guidPratica: this.guid_pratica,
                idquietanza: this.idquietanza
            });

            console.log("elenco simpli:",this.lista_simpli.elenco);

            this.is_mounted = true;
        },
        async activated() {
        }
    }
</script>
